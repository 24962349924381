import React from 'react'

import './styles.scss'

/**
 * A component to render feedback submission successful message.
 * @param {*} props 
 */
const FeedbackSucccessCard = () => {
  return (
    <div className='feedback-success-card'>
      <div className='logo' />
      <div className='title'>
        Thank you for your feedback!
      </div>
      <div className='info'>
      Your feedback helps us get better. We will look into your message and we will aim to provide you a better experience next time.  See you again.
      </div>
    </div>
  )
}


export default FeedbackSucccessCard
