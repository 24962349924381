import React from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import './styles.scss'

/**
 * A component to ratings form
 * @param {*} props 
 */
const NegativeFeedbackCard = (props) => {
  const {
    loading, 
    feedbackText,
    source,
    onFeedbackTextChange,
    goBackToReview,
    onFeedbackSubmit
  } = props

  return (
    <div className='eg-negative-feedback'>
      <div className='arrow-back' onClick={goBackToReview}/>
      <div className={ `logo ${source}` } />
      <div className='title'>
            Your feedback helps us get better.  Can you tell us how we can improve your experience.
      </div>
      <textarea
        className='feedback-input'
        type='textarea'
        onChange= {onFeedbackTextChange}
        placeholder='Tell us what we can improve'
      />
      {loading 
        ?  <Loader />
        : <div className={`submit ${!feedbackText && 'disabled' }` } onClick={onFeedbackSubmit}>SUBMIT</div>
      }
    </div>
  )
}

NegativeFeedbackCard.propTypes = {
  loading: PropTypes.bool, 
  feedbackText: PropTypes.string,
  source: PropTypes.string,
  onFeedbackTextChange: PropTypes.func,
  goBackToReview: PropTypes.func,
  onFeedbackSubmit: PropTypes.func
}

export default NegativeFeedbackCard
