import React from 'react'
import PropTypes from 'prop-types'
import StarRatings from 'react-star-ratings'

import Loader from '../../Loader'
import './styles.scss'


/**
 * A component to ratings form
 * @param {*} props 
 */
const Review = (props) => {
  const {
    loading, 
    rating,
    source = '',
    setRating,
    onRatingSubmit
  } = props

  return (
    <div className='zsf-review'>
      <div className={ `logo ${source}` } />
      <div className='title'>
            How was your visit?
      </div>
      <div className='subtitle'>
            Thank you for visiting today! If you have a minute, we’d love to hear about your overall experience so we can make your next visit even better.
      </div>
      <StarRatings
        rating={rating}
        starRatedColor="#fac723"
        changeRating={(newRating) => setRating(newRating)}
        numberOfStars={5}
        name='rating'
      />
      {loading 
        ?  <Loader />
        : <div className={`submit ${!rating && 'disabled' }` } onClick={onRatingSubmit}>SUBMIT</div>
      }
          
    </div>
  )
}

Review.propTypes = {
  loading: PropTypes.bool,
  rating: PropTypes.number,
  source: PropTypes.string,
  setRating: PropTypes.func,
  onRatingSubmit: PropTypes.func,
}

export default Review
