import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import './styles.scss'
import useFetch from '../../../hooks/useFetch'

const SocialMediaReviewLink = ({ media }) => {
  return (
    <a className={`social-link ${media.name}`} href={media.url}>
      {`Review us on ${media.name}`}
    </a>
  )
}

SocialMediaReviewLink.propTypes = {
  media: PropTypes.object,
}


/**
 * A component to render successful form submission information.
 * @param {*} props 
 */
const SucccessCard = ({ storeUuid }) => {
  let [socialMedia, setSocialMedia] = useState([])
  const [ requestState, callApi ] = useFetch()
  const { loading } = requestState

  useEffect(() => {
    const url = `${process.env.GATSBY_APP_REVIEWS_URL}/restaurants/${storeUuid}/social_media`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATSBY_APP_API_KEY
      }
    }
    callApi(url, options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { response } = requestState
    if(response) {
      const { links: socialLinks } = response
      const socialMediaLinks =  Object.keys(socialLinks).map((media) => ({
        name: media,
        url: socialLinks[media],
      }))
      setSocialMedia(socialMediaLinks)
    }
  }, [requestState])

  return (
    <div className='feedback-positive-card'>
      <div className='logo' />
      <div className='title'>
        Thank you for your feedback!
      </div>
      <div className='info'>
        We’re glad you had an amazing experience with us. Don’t forget to leave us a review. 
      </div>
      {loading 
        ? <Loader />
        : !requestState.error && socialMedia.map((media, index) => <SocialMediaReviewLink media={media} key={index} />)
      }
      
    </div>
  )
}

SucccessCard.propTypes = {
  storeUuid: PropTypes.string.isRequired,
}

export default SucccessCard
